<template lang="html">
  <div class="tabs">
    <ul role="tablist" class="tabs__header">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ tab__selected: tab.isActive, tab__disabled: tab.isDisabled }"
        @click="selectTab(tab)"
      >
        <a
          v-html="tab.title"
          :aria-controls="tab.hash"
          :aria-selected="tab.isActive"
          @click="selectTab(tab.hash, $event)"
          :href="tab.hash"
          role="tab"
        />
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  data: () => ({
      tabs: [],
      activeTabHash: "",
      activeTabIndex: 0,
      lastActiveTabHash: "",
  }),
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    const hasActive = this.tabs.some(tab => tab.isActive === true)
    if(!hasActive) {
      this.tabs[0].isActive = true;
    }
  },
  methods: {
    selectTab(selectedTab) {
      if (!selectedTab) return;
      if (selectedTab.isDisabled) return;
      if (selectedTab.onOpen){
        selectedTab.onOpen()
      }
      this.tabs.forEach((tab) => {
        tab.isActive = tab.title === selectedTab.title;
      });
    },
  },
};
</script>