<template>
  <section
    v-show="isActive"
    :aria-hidden="!isActive"
    :id="computedId"
    role="tabpanel"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    id: { default: null },
    title: { required: true },
    selected: { default: false },
    isDisabled: { default: false },
    onOpen: { default: null },
  },
  data: () => ({
      isActive: false,
  }),
  computed: {
    computedId() {
      return this.id ? this.id : this.title.toLowerCase().replace(/ /g, "-");
    },
  },
  mounted() {
    this.isActive = this.selected;
  },
};
</script>