import moment from "moment";

export function getCurrentDate() {
  return moment();
}

export function subtractTime(amount, date, timePeriod, format) {
  if (format)
    return moment(date)
      .subtract(amount, timePeriod)
      .format(format);
  return moment(date).subtract(amount, timePeriod);
}

export function addTime(amount, date, timePeriod, format) {
  if (format)
    return moment(date)
      .add(amount, timePeriod)
      .format(format);
  return moment(date).add(amount, timePeriod);
}

export function getWeekNumber(date = moment()) {
  return date.week();
}

export function getYearNumber(date = moment()) {
  return date.year();
}

export function getFormattedDate(date = moment()) {
  return date.format("YYYY-MM-DD");
}

export function getMonthNumber(date = moment()) {
  return date.month() + 1;
}
