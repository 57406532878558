<template>
  <div>
    <div @mouseover="showImage(itemnummer)" @mouseout="hideImage(itemnummer)">
      <router-link :to="{ name: 'ItemPage', params: { id: itemnummer, bu: bu } }"
        >{{ itemnummer }}
      </router-link>
    </div>
    <img
      :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${itemnummer}/original`"
      :id="'image__hover--' + itemnummer"
      class="table_image--hover"
    />
  </div>
</template>

<script>
export default {
  props: {
    itemnummer: [String, Number],
    bu: [String, Number]
  },
  methods: {
    showImage(i) {
      document.getElementById('image__hover--' + i).style.display = "block";
    },

    hideImage(i) {
      document.getElementById('image__hover--' + i).style.display = "none";
    },
  },
};
</script>